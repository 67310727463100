<template>
  <b-modal
    :visible="showModalInfor"
    :title="titulo"
    centered
    id="modal-center"
    @hide="hideModal"
    size="lg"
    :hide-footer="true"
  >
    <div>
      <div class="card ">
        <div class="card-body">
          <div class="mb-2 alinha-itens ">
            <strong>{{ titulo }}</strong>
          </div>
          <div class="row">
            <div
              class="col-xs-12 col-sm-5 col-md-5 col-lg-4  modal-informacao2 mb-3"
            >
              <div class="row ">
                <div class="col-xs-12 col-sm-2 sub-modal-informacao ">
                  <i
                    class="fa fa-user-circle image-infor"
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  class=" col-xs-12 col-sm-9 sub-modal-informacao font-modal-informacao-sub "
                >
                  <strong>Exigência para abertura de sinistro para acessórios</strong>
                </div>
              </div>
            </div>
            <div
              class="col-xs-12  col-sm-7 col-md-7 col-lg-8 font-modal-informacao"
            >
              <div v-html="mensagemHtml"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3  ">
        <div class="col-6">
          <button
            type="button"
            @click="salvarSinistro(true)"
            class="btn btn-block  btn-success sub-modal-informacao-btn "
          >
            Sim, possuo nota fiscal
          </button>
        </div>
        <div class="col-6 ">
          <button
            @click="salvarSinistro(false)"
            type="button"
            class="btn btn-block btn-secondary sub-modal-informacao-btn"
          >
            Não tenho nota fiscal
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ["showModalInfor", "titulo", "mensagemHtml"],
  data() {
    return {
      modificou: false,
    };
  },
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: this.modificou });
      this.loading = false;
    },
    salvarSinistro(value) {
      this.modificou = value;
      return this.hideModal();
    },
  },
};
</script>

<style>
.modal-header {
  border-bottom: 0px solid #ffffff;
}
.modal-informacao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.font-modal-informacao {
  font-size: 16px;
  text-align: justify;
}
.font-modal-informacao-sub {
  font-size: 13px;
}
.image-infor {
    margin-top: 6px;
  color: #23ad21;
  font-size: 38px;
  margin-right: 5px;
}
.sub-modal-informacao {
  align-items: center;
}
@media (min-width: 576px) {
  .modal-informacao2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .font-modal-informacao {
    font-size: 12px;
  }
  .font-modal-informacao-sub {
    font-size: 11px;
  }
  .image-infor {
    color: #23ad21;
    font-size: 31px;
  }
}
@media (max-width: 575px) {
  .font-modal-informacao {
    font-size: 12px;
  }
  .font-modal-informacao-sub {
    font-size: 12px;
  }
  .sub-modal-informacao-btn {
  font-size: 12px;
}
  .alinha-itens {
    text-align: center;
    padding-bottom: 8px;
  }
  .sub-modal-informacao {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
}
</style>
